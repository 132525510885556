import React from 'react';

import ProjectImg from '../ProjectImg';
import * as pics from '../../../assets/Projects/Charlemagne/CharlemagneIndex';


const Charlemagne = () => {


    return (
        <div className="project-resume">
            <h1>Charlemagne</h1>
            <h2>Site de ventes à emporter</h2>
            <p>
                La section hôtelière du
                <a href="https://lycee-charlespointet-thann.fr/" target="_blank" rel="noopener noreferrer"> Lycée Pointet de Thann </a>
                produit 4 jours sur 5 des plats pour ses clients.
                Or depuis le confinement il est impossible de servir des gens de l’extérieur.
                Nous avons donc eu la tâche de mettre en place un site de ventes à emporter pour les professeurs, élèves et personnel du lycée.
            </p>

            <a className='project-btn'
                href="https://nbviewer.org/github/Nahay/Charlemagne/blob/master/client/src/assets/Lycee_Pointet_-_Cahier_des_charges_VAE.pdf"
                target='_blank'
                rel='noopener noreferrer'
            >
                Cahier des charges
            </a>

            <h3>Présentation</h3>
            <p>
                <br/>
                Le site est séparé en deux parties : utilisateur et administrateur. <br/>
                Le panel utilisateur va permettre de passer commande selon les dates proposées, de voir ses commandes et de contacter le restaurant. <br/>
                Le panel admin pour sa part va permettre de gérer les plats, les dates, les commandes et les utilisateurs.
            </p>            

            <h3>Partie Utilisateur</h3>

            <div className='resume__multiple-img'>
                
                <ProjectImg img={pics.UserAccueilCo} alt="Accueil du site lorsque l'on est connecté"/>
                <ProjectImg img={pics.UserAccueilDeco} alt="Accueil en étant déconnecté"/>
            </div>

            <p>
                <br/>
                Dans l'onglet Commander on aura accès à un calendrier où on pourra voir les dates sur lesquelles une commande est possible (cases colorées). A droite,
                on a un aperçu de la date avec les plats qui y seront proposés et leur nombre restant. En cliquant sur commander, on devra se connecter afin de pouvoir
                effectuer une commande.
            </p>

            <div className='resume__multiple-img'>
                <ProjectImg img={pics.UserCommander} alt="Page Commander"/>
                <ProjectImg img={pics.UserConnexion} alt="Page de connexion utilisateur"/>
            </div>
        
            <p>
                On sera redirigé ici après avoir choisi une date. On a la liste de tous les plats disponibles. On va pouvoir indiquer le nombre souhaité
                pour chaque plat. Le total se met à jour en même temps que l'on va saisir une quantité.
                <br/> <br/>
                L'utilisateur va pouvoir indiquer à quelle heure il souhaite récupérer sa commande, laisser un commentaire s'il le souhaite, et indiquer 
            </p>
            <ProjectImg img={pics.UserPasserComm} alt="Interface pour passer une commande"/>

            <p>Voici la page qui va servir à contacter le restaurant.</p>   
            <ProjectImg img={pics.UserContact} alt="Page Contact"/>
    
            <p>
                
            </p>

            <h3>Partie Admin</h3>

            <ProjectImg img={pics.AdminAccueil} alt="Accueil de la partie Admin"/>
            <ProjectImg img={pics.AdminConnexion} alt="Page de connexion Admin"/>

            <ProjectImg img={pics.AdminCommMod} alt="Modification d'une commande"/>
            <ProjectImg img={pics.AdminCommDel} alt="Suppression d'une commande"/>

            <ProjectImg img={pics.AdminComptesAdd} alt="Ajout d'un compte"/>
            <ProjectImg img={pics.AdminComptesMod} alt="Modification d'un compte"/>
            <ProjectImg img={pics.AdminComptesDel} alt="Suppression d'un compte"/>

            <ProjectImg img={pics.AdminDateAdd} alt="Ajout d'une date"/>
            <ProjectImg img={pics.AdminDateMod} alt="Modification d'une date"/>
            <ProjectImg img={pics.AdminDateDel} alt="Suppression d'une date"/>

            <ProjectImg img={pics.AdminPlatsAdd} alt="Ajout d'un plat"/>
            <ProjectImg img={pics.AdminPlatsMod} alt="Modification d'un plat"/>          
            <ProjectImg img={pics.AdminPlatsDel} alt="Suppresion d'un plat"/>

            <p>

            </p>

            <h3>Le Back</h3>
            <p>
                Nous avons décidé d'utiliser MongoDB pour notre base de données, car nous avons voulu découvrir un nouvel environnement qu'est le NoSQL, n'ayant utilisé que MySQL jusqu'à présent sur des sites en PhP. <br/>
                Voici notre base de données :

                {/* screen base de données */}
            </p>

            <a className='project-btn'
                href="https://github.com/Nahay/Charlemagne"
                target='_blank'
                rel='noopener noreferrer'
            >
                Voir le dépôt GitHub
            </a>
            
        </div>        
    );
}

export default Charlemagne;