import React from "react";

const Mail = () => {
  return (
    <div className="mail__content">
      <div className="mail"></div>
    </div>
  );
};

export default Mail;