import React from 'react';


const ProjectImg = ({img, alt}) => {
    
    // la div qui prend tout l'écran pour le zoom
    const imgDiv = document.getElementById('imgDiv');
    // image qui est affichée zoomée
    const imgZoom = document.getElementById('imgZoom');

    const onClick = () => {
        // image zoom déjà affichée
        if (imgDiv.style.display === "flex") imgDiv.style.display = "none";
        // on a cliqué sur une image
        else {
            imgDiv.style.display = "flex";
            imgZoom.src = img;
        } 
    }

    return (
            <div className="project-img" onClick={onClick}>
                <img src={img} alt={alt}/>
                <span><em>{alt}</em></span>
            </div>              
    );
}

export default ProjectImg;