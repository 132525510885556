import React from 'react';

import ContactForm from '../components/ContactForm';



const Contact = () => {

    return ( 
        <div className="contact">
            <div className="contact__left">

                <ContactForm/>

            </div>
            <div className="contact__right">

            </div>
        </div>
    );
}

export default Contact;