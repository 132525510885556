import React from "react";

const Headphones = () => {
  return (
    <div className="headphones__content">
      <div className="headphones">
        <div className="protec"></div>
        <div className="micro"></div>
      </div>
    </div>
  );
};

export default Headphones;
