import React from "react";

const Cup = () => {
  return (
    <div className="cup__content">
      <div className="cup">
        <div className="tea"></div>
        <div className="shadow"></div>
      </div>
    </div>
  );
};

export default Cup;